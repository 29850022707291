.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
  background-color: #0F0F0F;
}
.home-section {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  border-color: #51515A;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
  border-bottom-width: 1px;
}
.home-hero {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-content {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
}
.home-main {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header {
  gap: var(--dl-space-space-unit);
  width: 567px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-heading {
  color: rgb(255, 255, 255);
  font-size: 64px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.home-caption {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-family: Poppins;
  line-height: 24px;
}
.home-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-buttons {
  gap: var(--dl-space-space-unit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-link {
  display: contents;
}
.home-get-started {
  width: 720px;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  text-decoration: none;
  background-color: #80FF44;
}
.home-text07 {
  color: rgb(12, 16, 12);
  font-size: 25px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 25px;
}
.home-highlight {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-avatars {
  width: 115px;
  height: var(--dl-size-size-small);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}
.home-image {
  left: 0px;
  position: absolute;
}
.home-image1 {
  left: var(--dl-space-space-twounits);
  position: absolute;
  object-fit: cover;
}
.home-image2 {
  left: var(--dl-space-space-fourunits);
  position: absolute;
  object-fit: cover;
}
.home-caption1 {
  color: rgb(255, 255, 255);
  font-family: Poppins;
  line-height: 24px;
}
.home-image3 {
  top: 150px;
  right: 0px;
  width: 650px;
  height: 900px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-image4 {
  top: 0px;
  right: 0px;
  width: 100%;
  margin: auto;
  position: absolute;
  object-fit: cover;
}
.home-image5 {
  display: none;
}
.home-footer {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  height: 316px;
  display: flex;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.home-content1 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-main-content {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-content2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-main1 {
  gap: var(--dl-space-space-threeunits);
  width: 713px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header1 {
  gap: var(--dl-space-space-unit);
  width: 526px;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text12 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  max-width: 80%;
  align-self: center;
  font-family: Poppins;
  line-height: 21px;
}
.home-categories {
  gap: var(--dl-space-space-fourunits);
  flex: 1;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.home-copyright {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text13 {
  color: rgb(196, 196, 196);
  width: 100%;
  font-size: 14px;
  font-family: Poppins;
  line-height: 21px;
}
.home-subscribe {
  gap: var(--dl-space-space-unit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-copyright1 {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: none;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text14 {
  color: #C4C4C4;
  width: 100%;
  font-size: 14px;
  font-family: Poppins;
  line-height: 21px;
}
@media(max-width: 991px) {
  .home-section {
    height: auto;
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-hero {
    gap: var(--dl-space-space-fourunits);
    flex: 1;
    position: relative;
    max-width: auto;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-content {
    position: relative;
    align-items: center;
  }
  .home-main {
    align-items: center;
  }
  .home-heading {
    text-align: left;
  }
  .home-caption {
    text-align: left;
  }
  .home-buttons {
    width: 100%;
  }
  .home-get-started {
    flex: 1;
  }
  .home-highlight {
    width: 100%;
    flex-direction: column;
  }
  .home-image3 {
    right: 0px;
    display: none;
    position: absolute;
    flex-direction: column;
  }
  .home-image5 {
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  .home-image6 {
    width: 600px;
  }
  .home-content1 {
    gap: var(--dl-space-space-threeunits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-copyright {
    display: none;
  }
  .home-copyright1 {
    display: flex;
  }
}
@media(max-width: 767px) {
  .home-content {
    gap: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-heading {
    font-size: 40px;
  }
  .home-caption {
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-family: Poppins;
    line-height: 24px;
  }
  .home-get-started {
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-caption1 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-image6 {
    width: 500px;
  }
  .home-content2 {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-main1 {
    gap: var(--dl-space-space-twounits);
  }
  .home-categories {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .home-header {
    width: 443px;
    height: 305px;
    align-items: flex-start;
  }
  .home-heading {
    height: 122px;
    max-width: 400px;
    align-self: center;
    padding-top: 20px;
  }
  .home-caption {
    color: rgb(255, 255, 255);
    width: auto;
    font-size: 15px;
    max-width: 90%;
    align-self: center;
    text-align: center;
    font-family: Poppins;
    line-height: 24px;
  }
  .home-get-started {
    width: 437px;
    border-radius: var(--dl-radius-radius-radius8);
  }
  .home-text07 {
    align-self: flex-start;
  }
  .home-caption1 {
    color: rgb(255, 255, 255);
    font-family: Poppins;
    line-height: 24px;
  }
  .home-image6 {
    right: -77px;
    width: 248px;
    bottom: 1px;
    height: auto;
  }
  .home-main1 {
    width: 439px;
  }
  .home-text12 {
    width: 100%;
    font-size: 13px;
    max-width: 90%;
    align-self: center;
    text-align: center;
    line-height: 24px;
  }
  .home-text14 {
    color: rgb(196, 196, 196);
    font-size: 14px;
    text-align: center;
    font-family: Poppins;
    line-height: 21px;
  }
}
